import Litepicker from "litepicker";
import { loadSallesTrouvees, loadRooms, recalculate } from "./utils";

export function deployFilters() {
  if (
    "matchMedia" in window &&
    window.matchMedia("(max-width:991px)").matches
  ) {
    let $legend = $(".form-filters__legend");

    $legend.on("click", function (e) {
      let $this = $(this);
      let $parent = $this.parent();

      $this.toggleClass("show");
      $parent.find(".form-row").toggleClass("d-none");
    });
  }
}

export function getRestauration() {
  let $choixRestauration = $("#choixRestauration");

  let queryUrl = $choixRestauration.data("query");
  let userType = $choixRestauration.data("type") == 1 ? "pro" : "part";
  let category = $choixRestauration.data("category");

  $.ajax({
    url: queryUrl + "?choix=" + userType + "&nums=" + category,
    method: "POST",
  })
    .done(function (data) {
      let response = JSON.parse(data);
      var fieldValue = $("<textarea />").html(response[0].libelleFr).text();
      $("#choixRestauration").text(fieldValue).show();
    })
    .fail(function (error) {
      console.log("Get Restauration - Error : " + error);
    });
}

export function setDropdownBehaviors(page = "selection") {
  // All drop-down menus containing filters
  $(".filter-list").on("click", "input, label", function (e) {
    e.stopPropagation();
  });

  $(".filter-list__button").on("click", function () {
    if (page == "venues") {
      loadRooms();
    } else {
      loadSallesTrouvees("A", page);
    }
  });

  // Drop-down "More Criteria"
  $(".dropdown-morecriteria .dropdown-menu .dropdown-toggle").on(
    "click",
    function (e) {
      let $this = $(this);
      let $subMenu = $this.next(".dropdown-menu");

      if (!$this.next().hasClass("show")) {
        $this
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }

      $subMenu.toggleClass("show");

      $this.parents(".dropdown.show").on("hidden.bs.dropdown", function (e) {
        $(".dropdown-submenu .show").removeClass("show");
      });

      return false;
    },
  );
}

export function setDropdownCriteriaSize() {
  if (
    "matchMedia" in window &&
    window.matchMedia("(min-width:992px)").matches
  ) {
    let formFiltersWidth = $(".form-filters").width();
    $(".dropdown-morecriteria > .dropdown-menu").innerWidth(formFiltersWidth);
  }
}

export function displaySelectedOptionsNb(page = "selection") {
  let nbOptions = 0;

  $(".filter-dropdown").each(function () {
    let $this = $(this);
    let selectedOptions = $this.find("input:checked").length;
    let triggerButton = $this.prev("button");

    if (selectedOptions > 0) {
      triggerButton.addClass("selected").attr("data-selected", selectedOptions);
      nbOptions += selectedOptions;
    } else {
      triggerButton.removeClass("selected").removeAttr("data-selected");
    }
  });

  if (page == "venues") {
    return nbOptions;
  }
}

export function setDatepicker(page = "selection") {
  // minDate
  let mindate = new Date();
  if (mindate.getDay() == 0)
    mindate = new Date(mindate.getTime() + 864e5 * 4).getTime();
  else if (mindate.getDay() < 3)
    mindate = new Date(mindate.getTime() + 864e5 * 3).getTime();
  else mindate = new Date(mindate.getTime() + 864e5 * 5).getTime();

  // startDate
  let startdate = null;
  if (page == "room") {
    let dateEvt = $("#choixDate").data("date");
    dateEvt = dateEvt.split(" ")[1].split("-");
    startdate = new Date(
      dateEvt[2] + "-" + dateEvt[1] + "-" + dateEvt[0],
    ).getTime();

    if (startdate < mindate) {
      startdate = mindate;
    }
  }

  const datepicker = new Litepicker({
    element: document.getElementById("choixDate"),
    format: "DD/MM/YYYY",
    lang: "fr-FR",
    startDate: startdate,
    minDate: mindate});
  datepicker.on('selected',(date,date2) => {
      if (page == "room") {
        recalculate();
        return false;
      }

      if (page == "selection" || page == "selection-bis") {
        loadSallesTrouvees("A", page);
      }
    })
}

export function setRangePrice(page = "selection") {
  // OnChange #choixPrix
  $("#choixPrix").change(function () {
    var $this = $(this);
    var value = $("#choixPrix").val();
    var $error = $(".filter-error");

    if (!/[a-zA-Z]/.test(value)) {
      $this.removeClass("is-invalid");
      loadSallesTrouvees("A", page);
    } else {
      $this.addClass("is-invalid");
      $error.html("Intervalle de prix uniquement. <br/>(ex: 2000-5000)");
    }
  });

  // Set jQuery UI Range Slider
  $("#range-price").slider({
    min: 0,
    max: 100000,
    step: 500,
    range: true,
    values: [0, 50000],
    slide: function (event, ui) {
      $("#choixPrix").val(ui.values[0] + "-" + ui.values[1]);
    },
    change: function (event, ui) {
      $("#choixPrix").change();
    },
  });
}

export function setRangeParticipants(
  minValue = 0,
  maxValue = 1800,
  page = "selection",
) {
  let defaultValue = $("#nbpart").val();
  defaultValue = defaultValue || 100;
  let rangeStep = minValue != 0 ? 1 : 10;

  // OnChange #partnumber
  $("#partnumber").on("change", function (e) {
    let number = parseInt($(this).val());
    minValue = minValue != 0 ? minValue : 6;
    if (isNaN(number) || number == "") {
      defaultValue = 100;
    } else if (number < minValue) {
      defaultValue = minValue;
    } else if (number > maxValue) {
      defaultValue = maxValue;
    } else {
      defaultValue = page != "parcours-bis" ? number : minValue;
    }
    $("#partnumber").val(defaultValue + " personnes");
    $("#nbpart").val(defaultValue);
    $("#range-participants").slider("value", defaultValue);

    if (page == "room") {
      recalculate();
      return false;
    }

    if (page == "selection" || page == "selection-bis") {
      loadSallesTrouvees("A", page);
    }
  });

  // Set jQuery UI Range Slider
  $("#range-participants").slider({
    min: Number(minValue),
    max: Number(maxValue),
    step: rangeStep,
    value: defaultValue,
    slide: function (event, ui) {
      $("#partnumber").val(ui.value + " personnes");
      $("#nbpart").val(ui.value);
    },
    change: function (event, ui) {
      if (page != "parcours-bis") {
        if (ui.value <= 6) {
          $("#range-participants").slider("value", 6);
          $("#partnumber").val(6 + " personnes");
          $("#nbpart").val(6);
        }

        $("#nbpart").change();
      }
    },
  });
}
