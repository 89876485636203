import { setMapSize } from "./map";

export function setSortByPrice() {
  $("#sorting").change(function () {
    let $this = $(this);
    let $listItem = $(".result-list__item");
    let listLength = $listItem.length;

    if ($this.val() == 1) {
      $listItem.each(function (index) {
        $(this).css("order", "");
      });
    } else {
      $listItem.each(function (index) {
        $(this).css("order", listLength - index);
      });
    }
  });
}

export function manageViewMode() {
  let $siteContent = $(".site-content");
  let viewMode = $('input[name="viewMode"]:checked').val();
  let $resultList = $(".result-list");
  let $resultBlock = $(".result-block");
  let mqXL = window.matchMedia("(min-width:1200px)").matches; // "mq" => "media queries"

  if (viewMode != 1) {
    $siteContent.removeClass("viewMode-map");
    if ("matchMedia" in window && mqXL) {
      $resultList.removeClass("result-list--map");
      $resultBlock.removeClass("result-block--list");
    }

    return false;
  }

  $siteContent.addClass("viewMode-map");

  if ("matchMedia" in window && mqXL) {
    $resultList.addClass("result-list--map");
    $resultBlock.addClass("result-block--list");
    setMapSize();
  }
}

// results-map
