import { generateRoomLink } from "./utils";

export function initMap() {
  map = new google.maps.Map(document.getElementById("map-canvas"), {
    center: {
      // Paris centre
      lat: 48.85188476035392,
      lng: 2.33734130859375,
    },
    zoom: 12,
    mapTypeControl: false,
  });
}

export function initMapRoom() {
  let $localisation = $("#localisation");
  let latitude = $localisation.data("lat");
  let longitude = $localisation.data("long");
  let customIcon = $localisation.data("iconselected");

  if ($("#map-canvas").length) {
    let locationLatLng = new google.maps.LatLng(latitude, longitude);

    let map = new google.maps.Map(document.getElementById("map-canvas"), {
      center: locationLatLng,
      zoom: 16,
      scrollwheel: true,
      mapTypeControl: false,
    });

    let marker = new google.maps.Marker({
      position: locationLatLng,
      map: map,
      icon: customIcon,
    });
  }
}

export function clearMapMarkers() {
 markers.forEach(function(marker){marker.setMap(null);})  
}

export function setMapMarkers(salles, page = "selection") {
  // On Load
  // google.maps.event.addDomListener(window, 'load', initialize);
  // Remove Events
  // var listener1 = marker.addListener("click", aFunction);
  // google.maps.event.removeListener(listener1);
  let $resultsList = $("#resultsList");
  let mapContainer = $("#map-canvas");
  let icondefault = mapContainer.data("icondefault");
  let iconselected = mapContainer.data("iconselected");
  let pathImg = $resultsList.data("pathcard");
  let infoWindows = [];
  let info = "";

  salles.forEach(function (s, index) {
    let id = s.id;
    let latitude = s.latitude / 10000000;
    let longitude = s.longitude / 10000000;
    let photo = pathImg + s.id + "/" + s.photo;
    let title = page == "selection" ? s.displayLibelle : s.libelle;
    let markerTitle = $("<span />").html(title).text();

    if (page == "selection") {
      let date = s.debug.cdateMin + " " + s.debug.dateMinS.replace("-", "/");
      let choixRestau = encodeURI(s.debug.choixRestau);
      let link = generateRoomLink(s);

      info = `<article class="result-block result-block--map" data-date="${date}" data-id="${s.id}" data-event="${s.debug.typeEvt}" data-nombre="${s.debug.nombre}" data-restau="${choixRestau}" data-package="${s.debug.packages[0].id}"><p class="result-block__title">${s.displayLibelle}</p><a href="${link}"><img src="${photo}" alt="Photo - ${s.displayLibelle}" style="width:250px" class="result-block__img" /></a><a href="${link}" class="result-block__details btn btn-sm btn-main">Détails de l'offre</a></article>`;
    } else {
      let link = $resultsList.data("pathroom") + "top/" + s.slug;

      info = `<article class="result-block result-block--map" data-id="${id}"><p class="result-block__title">${title}</p><a href="${link}"><img src="${photo}" alt="Photo - ${title}" style="width:250px" class="result-block__img" /></a><a href="${link}" class="result-block__details btn btn-sm btn-main">Détails de l'offre</a></article>`;
    }

    // Set InfoWindows
    infoWindows[id] = new google.maps.InfoWindow({
      content: info,
      position: {
        lat: latitude,
        lng: longitude,
      },
    });

    // Set Marker
    markers[id] = new google.maps.Marker({
      position: {
        lat: latitude,
        lng: longitude,
      },
      map: map,
      title: markerTitle,
      icon: icondefault,
    });

    markers[id].addListener("click", function (e) {
      markers[id].setIcon(iconselected);
      map.setZoom(14);
      map.setCenter(markers[id].getPosition());

      infoWindows.forEach(function(currentWindow) {
        currentWindow.close();
      });

      infoWindows[id].open(map, this);

      infoWindows[id].setPosition({
        lat: latitude,
        lng: longitude,
      });
    });

    markers[id].addListener("mouseover", function () {
      markers[id].setIcon(iconselected);
    });

    markers[id].addListener("mouseout", function () {
      markers[id].setIcon(icondefault);
    });

    if (index == 0) {
      map.setCenter(markers[id].getPosition());
    }
  });
}

export function setStickyMap() {
  let scrollValue = window.scrollY;
  let headHeight = $(".header-site").height() + $(".result-tools").height();
  let map = $(".results-map");

  if (
    "matchMedia" in window &&
    window.matchMedia("(min-width:1200px)").matches
  ) {
    if (
      $('input[name="viewMode"]:checked').val() == 1 &&
      $(".result-list").height() > $(window).height()
    ) {
      if (scrollValue >= headHeight) {
        map.addClass("results-map--sticky");
      } else {
        map.removeClass("results-map--sticky");
      }
    }
  }
}

export function setMapSize() {
  // console.log("Result List : " + $(".result-list").height());
  // console.log("Window height : " + $(window).height());

  if (
    $('input[name="viewMode"]:checked').val() == 1 &&
    $(".result-list").height() > $(window).height()
  ) {
    $(".results-map").addClass("results-map--full");
  } else {
    $(".results-map").removeClass("results-map--full");
  }
}
